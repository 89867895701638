import React, { useEffect, useRef, useState, useTransition } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Popover, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from './axios';
import moment from 'moment';
import './App.css';

const sample1 = require('./samples/1.json');
const sample2 = require('./samples/2.json');
const sample3 = require('./samples/3.json');


const sampleData = {
  '1': sample1,
  '2': sample2,
  '3': sample3,
};

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const ipfs = axios.create({ 
  baseURL: process.env.REACT_APP_METADATA_PATH,
  headers: {
    'Accept': 'text/plain', 
    'Accept-Encoding': 'identity'
  },  
 });


// function adjustMarginTop() {
// var windowWidth = window.innerWidth;
// var marginTop = 0;
// var minWidthForMarginTopIncrease = 532;
// var startingMarginTop = 10;
// var maxMarginTop = 100;
// var heightDiffFactor = .35;

// if (windowWidth < minWidthForMarginTopIncrease) {
//   var heightDiff = minWidthForMarginTopIncrease - windowWidth;
//   marginTop = startingMarginTop + heightDiff * heightDiffFactor;
//   if (marginTop > maxMarginTop) {
//     marginTop = maxMarginTop;
//   }
// }

// console.log("Setting marginTop: "+marginTop);
// document.getElementById('tlnft-imgbox').style.marginTop = marginTop + 'px';

// // Store the margin-top value in localStorage
// localStorage.setItem('marginTop', marginTop);
// }

// if (window.self !== window.top && document.referrer.indexOf('tikiisland.io') === -1) {
//   // Call the adjustMarginTop function on page load
//   window.addEventListener('load', adjustMarginTop);  
//   // Call the adjustMarginTop function on the resize event
//   window.addEventListener('resize', adjustMarginTop);
// }


export default function App() {

  const [carving, setCarving] = useState({})
  const [story, setStory] = useState({})
  const [idToReport, setIdToReport] = useState(null)
  const [nftToReport, setNftToReport] = useState(null)
  //const [loading, setLoading] = useState(false)
  const nft_container_ref = useRef(null)
  const tlnft_imgbox = useRef(null)
  const [error, setError] = useState(null)
  const [isPending, startTransition] = useTransition()


  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const sample = searchParams.get('sample');
  const display = searchParams.get('display');


  
  useEffect(() => {

    startTransition(async () => {

      const getMetaData = async () => {
        try {
          if (!id) {
            setStory(null);
            return null;
          }
          if (sample) {
            const data = sampleData[id];            
            setStory(data);
            return data;
          } else {
            //const { data } = await GAxios.get(`${process.env.REACT_APP_METADATA_PATH}/${id}.json`)
            const { data } = await ipfs.get(`/${id}.json`);
            //console.log(JSON.stringify(data, null, 2));
            setStory(data);
            return data;
          }
        } catch (err) {          
          console.log(`${process.env.REACT_APP_METADATA_PATH}/${id}.json`);
          console.log("Failed to load NFT - Trying again...", err);
          setTimeout(getMetaData, 5000);
        }
      }

      let metadata = await getMetaData()

      const getCarving = async () => {
        try {
          //let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/query/${id}`)
          if (sample) {
            const data = {carving: []};
            data.story = metadata;
            setCarving(data);
          } else {
            let { data } = await api.get(`/query/${id}`);
            data.story = metadata;            
            setCarving(data)            
          }
        } catch (err) {
          if (err.response) return setError(err.response.status)
          if (err.request) return setError('ERR_CONNECTION_REFUSE')
        }
        return true;
      }

      try {
        await getCarving();
      } catch (err) {
        console.log(err, err);
      }
      return true;
    })


  }, [id, sample])


  const handleOpen = (n, id = null, nft = null) => {
    setIdToReport(id)
    setNftToReport(nft);
    return nft_container_ref.current.classList.add(`tl-nft-overly-${n}-active`)
  }

  const handleClose = (n) => {
    const menu_close_icon = document.getElementsByClassName('tl-nft-close-icon')

    for (let i = 0; i < menu_close_icon.length; i++) {
      nft_container_ref.current.classList.remove(`tl-nft-overly-${n}-active`)
    }

    setIdToReport(null)
    setNftToReport(null);
  }


  useEffect(() => {
    if (!display || !carving.story) return

    handleOpen(display)

  }, [display, carving])


  const adjustMarginTop = () => {
    var windowWidth = window.innerWidth;
    var marginTop = 0;
    var minWidthForMarginTopIncrease = 532;
    var startingMarginTop = 10;
    var maxMarginTop = 100;
    var heightDiffFactor = .35;
    
    if (windowWidth < minWidthForMarginTopIncrease) {
      var heightDiff = minWidthForMarginTopIncrease - windowWidth;
      marginTop = startingMarginTop + heightDiff * heightDiffFactor;
      if (marginTop > maxMarginTop) {
        marginTop = maxMarginTop;
      }
    }
          
    //document.getElementById('tlnft-imgbox').style.marginTop = marginTop + 'px';
    if (tlnft_imgbox.current) {      
      tlnft_imgbox.current.style.marginTop = marginTop + 'px';
      // Store the margin-top value in localStorage
      //localStorage.setItem('marginTop', marginTop);
    } 
  }

  useEffect(() => {
    if (window.self !== window.top && document.referrer.indexOf('tikiisland.io') === -1) {
      // For some reason this became no longer necessary.... CSS is bullshit
      //adjustMarginTop();

      //console.log("Adding event listeners");
      // Call the adjustMarginTop function on page load
      //window.addEventListener('load', adjustMarginTop);  
      // Call the adjustMarginTop function on the resize event
      //window.addEventListener('resize', adjustMarginTop);
    }
  }, [carving]);


  const report = async (event) => {
    event.preventDefault();
    if (!idToReport) return
    if (!nftToReport) return
    //setLoading(true)

    try {
      //const { data } = await axios.get(`/report/${nftToReport}/${idToReport}`);
      await api.get(`/report/${nftToReport}/${idToReport}`);
      //setLoading(false)
      handleClose('report')
      setIdToReport(null);
      setNftToReport(null);

    } catch (err) {
      if (err) setError(err.response.status)
    }

  }

  const openWallet = (wallet) => {
    window.open(`https://opensea.io/${wallet}`, '_blank');
  }


  if (isPending) return <></>

  if (!id) return <div className='great_things'>
    <img src='./images/LOGO-400x124.png' alt='tiki island logo' className='logo' />
    <h2>Great things to come</h2>
    <p>Visit our website at <br /> <a href='https://tikiisland.io/' target='_blank' rel="noreferrer">Tikiisland.io</a> </p>
  </div>

  // const CtrlClickPopover = (
  //   <Popover id="popover-ctrl-click-notice" title="Ctrl-Click">
  //     Please <strong>Ctrl-Click</strong> the link to navigate to the page.
  //   </Popover>
  // );

  const CtrlClickTooltip = (
    <Tooltip placement="right" className="in" id="tooltip-right">
      <h5>Push Control key + Mouse click to navigate.</h5>
    </Tooltip>
  );

  const ctrlLink = (href, content, showOverlay) => {
    if (showOverlay) {
      return (<OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={CtrlClickTooltip}
      >
        <a className="tl-nft-tooltip" href={href} target='_blank' rel='noreferrer'>{content}</a>
      </OverlayTrigger>);
    } else {
      return (<a className="tl-nft-tooltip" href={href} target='_blank' rel='noreferrer'>{content}</a>);
    }
  }

  //console.log("document.referrer="+document.referrer);
  const showOverlay = document.referrer.match(/^.*[.:]tikiisland.io(\/.*)?$/) ? false : true;
  //console.log("showOverlay="+showOverlay);


  /*
              <div className="tl-nft-content-text">
                <h2 style={{ marginTop: '5px' }} >Report Content</h2>
                <p className='text'>Report bad content bullying, harassment, inappropriate content.</p>
                <div id="tl-nft-story-text-info" className="tl-nft-content-text-contant content-4">
                  <button onClick={report} className='report'>Report Now {loading && <div className='loader'><div className="lds-dual-ring"></div></div>} </button>
                  <button onClick={() => handleClose(4)} className='cancel'>Cancel</button>
                </div>
              </div>
                */
 

  return (
    <div ref={nft_container_ref} className="tl-nft-con">
      {
        error
          ? <div className='error-tab' style={{ backgroundImage: `url('${story.image}')` }}>
            {/* <img src='./images/LOGO-1000x310.png' alt='tiki island logo' className='logo' /> */}
            {/* <p>The Tikis powers are strong and they have controle right now! But don't worry We will regain  controle soon please check back soon!</p> */}
            <span className='error-code'>Error Code: {error}</span>
          </div>


          : carving.message === 'not_exist'

            ? <div className='tl-nft-con'>
              <img src='./images/LOGO-400x124.png' alt='tiki island logo' className='logo' />
              <h2>Great things to come</h2>
              <p>Visit our website at <br /> <a href='https://tikiisland.io/' target='_blank' rel="noreferrer">Tikiisland.io</a> </p>
            </div>


            : carving.story && <div ref={tlnft_imgbox} className="tl-nft-img-box">
              <img className="tl-nft-bg-img" src={carving.story.image.replace("ipfs://", "https://ipfs.io/ipfs/")}/>
              <nav className="tl-nft-menu">                
                  <img onClick={() => handleOpen('info')} className="tl-nft-menu-info" src="./images/1-info.png" alt="info button" />
                  <img onClick={() => handleOpen('story')} className="tl-nft-menu-story" src="./images/2-story.png" alt="story button" />
                  <img onClick={() => handleOpen('carving')} className="tl-nft-menu-carving" src="./images/3-carving.png" alt="carving button" />                
              </nav>

              <div className="tl-nft-info-content">
                <div onClick={() => handleClose('info')} className="tl-nft-close-icon"> <i className="fas fa-times-circle"></i></div>
                <h2>Tiki Island</h2>

                <div className="tl-nft-content-text">


                  <span className="tl-nft-text-header">Tiki ID:</span>
                  <span className="tl-nft-text-info">#{id}</span>


                </div>
                <div className="tl-nft-content-text">
                  <span className="tl-nft-text-header">Website:</span>
                  <span className="tl-nft-text-info">
                    {ctrlLink('https://tikiisland.io/', "Tikiisland.io", showOverlay)}
                  </span>
                </div>

                <div className="tl-nft-content-text">
                  <span className="tl-nft-text-header">Legend:</span>
                  <span className="tl-nft-text-info">
                  {ctrlLink('https://tikiisland.io/legend', "The Legend Of Tiki Island", showOverlay)}                  
                  </span>
                </div>

                <div className="tl-nft-content-text">
                  <span className="tl-nft-text-header">Carve Your Story:</span>
                  <span className="tl-nft-text-info">
                    {ctrlLink('https://carving.tikiisland.io/', "Tiki Carving", showOverlay)}
                  </span>
                </div>

                <div className="tl-nft-content-text">
                  <span className="tl-nft-text-header">Social Media:</span>
                  <div className='tl-nft-social-media'>
                    {ctrlLink('https://twitter.com/Tikiisland_io', (
                      <svg width={40} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                      </svg>),
                      showOverlay)}
 
                    {ctrlLink('https://discord.gg/gkvY2TGnFS', (
                      <svg width={44} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" />
                      </svg>),
                      showOverlay)}
                  </div>
                </div>

                <div className='tl-nft-terms-conditions'>
                  {ctrlLink('https://tikiisland.io/tos', (<span className="tl-nft-text-info">Terms &amp; Conditions</span>), showOverlay)}
                </div>
              </div>

              <div className="tl-nft-story-content">
                <div onClick={() => handleClose('story')} className="tl-nft-close-icon"> <i className="fas fa-times-circle"></i></div>
                <h2>Tiki Story</h2>
                <p className="tl-nft-story-text-info" style={{paddingRight: "10px"}}> {carving.story.story ? carving.story.story.replace(/(?:\\)?\\n/g, "\n") : ""} </p>
              </div>

              <div className="tl-nft-carving-content">
                <div onClick={() => handleClose('carving')} className="tl-nft-close-icon"><i className="fas fa-times-circle"></i></div>
                <h2>Tiki Carvings</h2>
                <div id="nft-details-text" className="tl-nft-carving-content-list">
                  {
                    carving.carving.map((carving, index) => (
                      <div key={index} className="tl-nft-story-text-info">

                        {
                          carving?.is_sensitive
                            ? <div className='accordion'>
                              <div className="row">
                                <div className="col">
                                  <div className="tabs">
                                    <div className="tab">
                                      <input type="checkbox" id={`id-${index}`} />
                                      <label className="tab-label" htmlFor={`id-${index}`}>Story may contain "Sensitive Content!" click to view at your own risk.</label>
                                      <div className="tab-content">
                                        <div className='tl-nft-text-header'>
                                          <h3 style={{marginTop: "10px"}}> Title: {carving.name} </h3>                                          
                                        </div>
                                        <p> Story : {carving.carving_txt} </p>
                                        {
                                          Number(carving.wallet_visibility) === 1 && <p style={{ cursor: 'pointer' }} onClick={() => openWallet(carving?.wallet)} >Wallet : {carving?.wallet.substring(0, 4) + '...' + carving?.wallet.substring(carving?.wallet.length - 4, carving?.wallet.length)}</p>
                                        }
                                        <h4 className='date'>
                                            <p style={{fontSize: "14px"}}>Date : {moment(new Date(carving.date)).format("DD/MM/YY")}</p>
                                            <svg onClick={() => handleOpen('report', carving.carvingId, carving.nftId)} width={19} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                              <path d="M64 496C64 504.8 56.75 512 48 512h-32C7.25 512 0 504.8 0 496V32c0-17.75 14.25-32 32-32s32 14.25 32 32V496zM476.3 0c-6.365 0-13.01 1.35-19.34 4.233c-45.69 20.86-79.56 27.94-107.8 27.94c-59.96 0-94.81-31.86-163.9-31.87C160.9 .3055 131.6 4.867 96 15.75v350.5c32-9.984 59.87-14.1 84.85-14.1c73.63 0 124.9 31.78 198.6 31.78c31.91 0 68.02-5.971 111.1-23.09C504.1 355.9 512 344.4 512 332.1V30.73C512 11.1 495.3 0 476.3 0z" />
                                            </svg>
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            : <>
                              <div className='tl-nft-text-header'>
                                <h3 style={{marginTop: "10px"}}> Title: {carving.name} </h3>                                
                              </div>
                              <p> Story : {carving.carving_txt} </p>
                              {
                                Number(carving.wallet_visibility) === 1 && <p style={{ cursor: 'pointer' }} onClick={() => openWallet(carving?.wallet)} >Wallet : {carving?.wallet.substring(0, 4) + '...' + carving?.wallet.substring(carving?.wallet.length - 4, carving?.wallet.length)}</p>
                              }
                              <h4 className='date'>
                                  <p style={{fontSize: "14px"}}>Date : {moment(new Date(carving.date)).format("DD/MM/YY")}</p>
                                  <svg onClick={() => handleOpen('report', carving.carvingId, carving.nftId)} width={19} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M64 496C64 504.8 56.75 512 48 512h-32C7.25 512 0 504.8 0 496V32c0-17.75 14.25-32 32-32s32 14.25 32 32V496zM476.3 0c-6.365 0-13.01 1.35-19.34 4.233c-45.69 20.86-79.56 27.94-107.8 27.94c-59.96 0-94.81-31.86-163.9-31.87C160.9 .3055 131.6 4.867 96 15.75v350.5c32-9.984 59.87-14.1 84.85-14.1c73.63 0 124.9 31.78 198.6 31.78c31.91 0 68.02-5.971 111.1-23.09C504.1 355.9 512 344.4 512 332.1V30.73C512 11.1 495.3 0 476.3 0z" />
                                  </svg>
                              </h4>
                            </>
                        }



                      </div>
                    ))
                  }
                </div>
              </div>
                

            </div>
      }
    </div>

  )


}